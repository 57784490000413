// Import libraries
import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


// Import global styles
import GlobalStyle from '../styles/global';


// Import component
import Layout from '../layout/Layout';
import SEO from '../layout/SEO';


// Component styles
const StyledBanner = styled.div`
    background-image: url(${props => !!props.image.childImageSharp ? props.image.childImageSharp.fluid.src : props.image});
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;

    & .overlay {
        display: grid;
        grid-template-rows: 1fr 6rem;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .7);
        align-items: center;
        justify-content: center;

        & .content {
            display: grid;
            grid-template-rows: repeat(2, min-content);
            grid-row-gap: 5rem;
            max-width: 1100px;
            margin-top: 100px; /* Equal to header height */
            padding: 0 var(--padding-right) 0 var(--padding-left);
            color: #fff;

            & .primary-color {
                color: var(--color-primary);
            }

            & h1 {
                font-size: var(--font-size-banner-h1);
                font-weight: var(--font-weight-banner-h1);
                line-height: var(--line-height-banner-h1);
            }

            &-link {
                justify-self: start;
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                background-color: var(--color-primary);
                border: 2px solid var(--color-primary);
                border-radius: 0;
                font-size: 2rem;
                padding: 1rem 2rem;
                color: #fff;
                font-weight: 400;

                & span {
                    margin-right: 10px;
                }
            }

            &-link:link,
            &-link:visited {
                color: currentColor;
                text-decoration: none;
                outline: 0;
                transition: color .25s ease;
            }

            &-link:hover,
            &-link:focus {
                text-decoration: none;
                color: currentColor;
                outline: 0;
                transform: translateY(-3px);
                box-shadow: 5px 5px 5px rgba(0, 0, 0, .1);
            }

            &-link:active {
                text-decoration: none;
                color: currentColor;
                outline: 0;
                transform: translateY(0);
                box-shadow: none;
            }
        }

        & .scroll-down-button {
            display: grid;
            align-items: center;
            justify-items: center;
            width: 6rem;
            height: 6rem;
            margin-left: var(--padding-left);
            font-size: 2.5rem;
            background-color: #fff;
            color: #000;
            cursor: pointer;
            transition: background-color .25s ease-in-out,
                        color .25s ease-in-out;
            
            &:hover {
                background-color: var(--color-primary);
                color: #fff;
            }
        }
    }
`;

const StyledIntroductionContainer = styled.div`
    display: grid;
    justify-items: center;

    & .content {
        display: grid;
        grid-template-rows: repeat(4, min-content);
        grid-row-gap: 5rem;
        max-width: 1100px;
        padding: 10rem var(--padding-right) 10rem var(--padding-left);

        & .primary-color {
            color: var(--color-primary);
        }
        
        & h1 {
            font-size: var(--font-size-body-h1);
            font-weight: var(--font-weight-body-h1);
            line-height: var(--line-height-body-h1);
        }

        & h2 {
            margin-top: -4rem;
            font-weight: var(--font-weight-body-h2);
            font-size: var(--font-size-body-h2);
            line-height: var(--line-height-body-h2);
        }

        & p {
            font-weight: var(--font-weight-body-p);
            font-size: var(--font-size-body-p);
            line-height: var(--line-height-body-p);
        }

        &-link {
            justify-self: start;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            background-color: var(--color-primary);
            border: 2px solid var(--color-primary);
            border-radius: 0;
            font-size: 2rem;
            padding: 1rem 2rem;
            color: #fff;
            font-weight: 400;

            & span {
                margin-right: 10px;
            }
        }

        &-link:link,
        &-link:visited {
            color: #fff;
            text-decoration: none;
            outline: 0;
            transition: color .25s ease;
        }

        &-link:hover,
        &-link:focus {
            text-decoration: none;
            color: #fff;
            outline: 0;
            transform: translateY(-3px);
            box-shadow: 5px 5px 5px rgba(0, 0, 0, .1);
        }

        &-link:active {
            text-decoration: none;
            color: #fff;
            outline: 0;
            transform: translateY(0);
            box-shadow: none;
        }
    }
`;

const StyledOurFeatures = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @media only screen and (max-width: 800px) {
        grid-template-columns: 1fr;
    }

    & .feature {
        width: 50vw;
        height: 50vw;

        @media only screen and (max-width: 800px) {
            width: 100vw;
            height: 100vw;

            &.content {
                height: auto;
            }
        }
    }

    & .feature.content {
        display: grid;
        grid-template-rows: repeat(3, max-content);
        align-content: center;
        grid-row-gap: 2rem;
        padding: var(--padding-right) var(--padding-right) var(--padding-left) var(--padding-left);
        background-color: var(--color-background-2);

        & h1 {
            font-size: var(--font-size-body-h1);
            font-weight: var(--font-weight-body-h1);
            line-height: var(--line-height-body-h1);
        }

        & .feature__summary {
            font-size: var(--font-size-body-p);
            font-weight: var(--font-weight-body-p);
            line-height: var(--line-height-body-p);
        }

        & .content-link {
            justify-self: start;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            background-color: var(--color-primary);
            border: 2px solid var(--color-primary);
            border-radius: 0;
            font-size: 2rem;
            padding: 1rem 2rem;
            color: #fff;
            font-weight: 400;

            & span {
                margin-right: 10px;
            }
        }

        & .content-link:link,
        & .content-link:visited {
            color: #fff;
            text-decoration: none;
            outline: 0;
            transition: color .25s ease;
        }

        & .content-link:hover,
        & .content-link:focus {
            text-decoration: none;
            color: #fff;
            outline: 0;
            transform: translateY(-3px);
            box-shadow: 5px 5px 5px rgba(0, 0, 0, .1);
        }

        & .content-link:active {
            text-decoration: none;
            color: #fff;
            outline: 0;
            transform: translateY(0);
            box-shadow: none;
        }
    }
`;

const StyledFeatureImage = styled.div`
    background-image: url(${props => !!props.image.childImageSharp ? props.image.childImageSharp.fluid.src : props.image});
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;

    @media only screen and (max-width: 800px) {
        &.feature-image--2 {
            grid-row: 4 / 5;
        }

        &.feature-image--4 {
            grid-row: 8 / 9;
        }
    }
`;


// Create component
const IndexPage = () => {
    const data = useStaticQuery(graphql`
        query {
            bannerImage: file(relativePath: { eq: "banner/index/banner.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2048, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            webOntwerpImage: file(relativePath: { eq: "index/Web-ontwerp.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 800, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            webwinkelImage: file(relativePath: { eq: "index/Webwinkel.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 800, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            webApplicatiesImage: file(relativePath: { eq: "index/Web-applicaties.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 800, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            onlineAdverterenImage: file(relativePath: { eq: "index/Online-ads-editted.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 800, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);


    // Handle scrolling down to below banner
    const handleScrollDown = () => {
        // Get banner height
        const bannerHeight = document.querySelector('.banner').offsetHeight;
        
        // Scroll to place underneath banner
        window.scrollTo({
            top: bannerHeight,
            left: 0,
            behavior: 'smooth'
        });
    };


    return (
        <React.Fragment>
            <GlobalStyle />
            <SEO title="Home" />
            <Layout withBanner={true}>
                <StyledBanner
                    className="banner"
                    image={data.bannerImage}
                >
                    <div className="overlay">
                        <div className="content">
                            <h1><span className="primary-color">Boumedia</span> werkt samen met grote en kleine- en middelgrote bedrijven om hun digitale aanwezigheid te optimaliseren.</h1>
                            <Link to="/onze-diensten" className="content-link">
                                <span>Bekijk onze diensten</span>
                                <FontAwesomeIcon icon={faLongArrowRight} className="icon" />
                            </Link>
                        </div>

                        <div className="scroll-down-button" onClick={handleScrollDown}>
                            <FontAwesomeIcon icon={faArrowDown} className="icon" />
                        </div>
                    </div>
                </StyledBanner>

                <StyledIntroductionContainer>
                    <div className="content">
                        <h1>Op dit moment hebben wij klanten in Nederland, Cura&ccedil;ao, Australi&euml;, en Zuid-Afrika.</h1>

                        <h2>Wij helpen onze klanten van klein en middelgroot formaat om vooruit te bewegen in dit digitale tijdperk.</h2>

                        <p>Bij <span className="primary-color">boumedia</span> specialiseren wij ons in web ontwerp, webwinkels, web applicaties, online adverteren, online vindbaarheid, aantrekkelijke inhoud, de beste beveiliging, en optimale hosting. Wij maken gebruik van de nieuwste technologi&euml;n op het gebied van web ontwikkeling zodat onze klanten altijd meegaan met de laatste digitale trends. Elke klant is uniek en heeft een andere behoefte en smaak. Dat snappen wij en daarom ontwikkelen wij onze producten geheel op maat door nauwlettend samen te werken met de klant. Bij ons is daarom ieder product een uniek product.</p>

                        <Link to="/onze-diensten" className="content-link">Bekijk onze diensten</Link>
                    </div>
                </StyledIntroductionContainer>

                <StyledOurFeatures>
                    <div className="feature content">
                        <h1>Web Ontwerp</h1>

                        <p className="feature__summary">Wij zijn altijd op de hoogte van de nieuwste trends in web ontwerp en brengen u graag op de hoogte van alle mogelijkheden. Zo vinden wij het belangrijk dat uw website bezoekers op de meest gebruiksvriendelijke manier kunnen navigeren op uw site. Doormiddel van voorbeelden die wij aan uw voorleggen komen wij te weten wat uw smaak is, en kunnen wij uw persoonlijke smaak verwerken in uw website.</p>

                        <Link to="/onze-diensten#web-ontwerp" className="content-link">Meer weten</Link>
                    </div>

                    <StyledFeatureImage
                        className="feature image feature-image--1"
                        image={data.webOntwerpImage}
                    ></StyledFeatureImage>

                    <StyledFeatureImage
                        className="feature image feature-image--2"
                        image={data.webwinkelImage}
                    ></StyledFeatureImage>

                    <div className="feature content">
                        <h1>Webwinkels</h1>

                        <p className="feature__summary">Door onze jarenlange ervaring met webwinkels kunnen wij ervoor zorgen dat uw producten het beste tot zijn recht komen op uw website. Wij werken ook graag met u mee om uw producten visueel nog beter tot hun recht te laten komen. Daarnaast kunnen wij u ook assisteren met online marketing van uw webwinkel zoals, cross-selling, email marketing, Google ads, social media marketing en nog veel meer.</p>

                        <Link to="/onze-diensten#webwinkels" className="content-link">Meer weten</Link>
                    </div>

                    <div className="feature content">
                        <h1>Web Applicaties</h1>

                        <p className="feature__summary">Web applicaties kunnen de dagelijkse werkzaamheden in uw bedrijf effici&euml;nter maken. Tevens proberen wij er altijd naar te streven om zoveel als mogelijk papierwerk te digitaliseren. In samenwerking met het personeel in uw bedrijf en door het analyseren van uw bedrijfsprocessen kunnen wij identificeren welke processen geautomatiseerd kunnen worden doormiddel van een web applicatie. Als u geintereseerd bent kunnen wij een vrijblijvende analyse uitvoeren en met een voorstel komen.</p>

                        <Link to="/onze-diensten#web-applicaties" className="content-link">Meer weten</Link>
                    </div>

                    <StyledFeatureImage
                        className="feature image feature-image--3"
                        image={data.webApplicatiesImage}
                    ></StyledFeatureImage>

                    <StyledFeatureImage
                        className="feature image feature-image--4"
                        image={data.onlineAdverterenImage}
                    ></StyledFeatureImage>

                    <div className="feature content">
                        <h1>Online Adverteren</h1>

                        <p className="feature__summary">Wij kunnen uw online marketing geheel of gedeeltelijk uit handen nemen. Wij brengen graag voor u in kaart welke mogelijkheden er zoal zijn voor uw bedrijf. Social Media Marketing, Email Marketing en Google Ads zijn enkele voorbeelden om uw digitale aanwezigheid te verbeteren. Wij zijn flexibel en passen ons aan bij uw begroting.</p>

                        <Link to="/onze-diensten#online-adverteren" className="content-link">Meer weten</Link>
                    </div>
                </StyledOurFeatures>
            </Layout>
        </React.Fragment>
    );
};


// Export component by default
export default IndexPage;